const Noty = require('../global_assets/js/plugins/notifications/noty.min.js');
const autosize = require('autosize/dist/autosize.min.js');
global.$ = global.jQuery = $;
global.Noty = Noty;

var Custom = function() {


    var _initNoty = function() {
        Noty.overrideDefaults({
            theme: 'limitless',
            layout: 'topRight',
            type: 'alert',
            timeout: 2500
        });

        window.flashes && window.flashes.forEach((flash) => {
            new Noty({
                type: flash.type,
                text: flash.message
            }).show();
        });
    };

    var _highlightLinks = function() {
        $('a[href="' + window.location.pathname + '"]').addClass('active');
        $('a[href="' + window.location.pathname + '"]').closest('ul.nav.nav-group-sub').show()
        $('a[href="' + window.location.pathname + '"]').closest('li.nav-item-submenu').addClass('nav-item-open');
    };

    var _initTextareaAutosize = function() {
        autosize(document.querySelectorAll('textarea'));
    };

    return {
        initAfterLoad: function() {
            _initNoty();
            _highlightLinks();
            _initTextareaAutosize();
        }
    }
}();

// Initialize module
// ------------------------------

// When content is loaded
// document.addEventListener('DOMContentLoaded', function() {
//     Custom.initBeforeLoad();
//     Custom.initCore();
// });

// When page is fully loaded
window.addEventListener('load', function() {
    Custom.initAfterLoad();
});